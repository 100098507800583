@value popoverBorder, popoverShadow, silver, black, white, bg-white, light-grey, tableConfirmedColor from '../../../Assets/styles/variables/colors.css';

.ekgTable {
  height: 100%;
  position: relative;
}

.confirmedIcon {
  height: 16px;
  width: 16px;
  fill: tableConfirmedColor;
  margin-right: 6px;
}

.confirmed {
  margin: 8px 0 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: left;
  align-self: flex-start;
  font-family: 'proxima-nova';
  font-size: 14px;
  font-weight: 500;
  color: tableConfirmedColor;
}

.ekgPreview {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
}

.tag {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 30px;
  width: 86px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #EFEFEF;
  background-color: bg-white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 3px 4px 0px light-grey;
  overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
}
.sixLIcon {
  width: 28px;
  height: 17px;
  position: absolute;
  right: 16px;
  bottom: 4px;
  opacity: .8;
}
.sixLIcon path {
  fill: #000;
}
.heartIcon {
  width: 18px;
    height: 18px;
  /*margin-left: 8px;*/
    fill: #d0021b;
}
.bpm {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  font-family: 'anonymous-pro';
  /*padding-left: 6px;*/
  /*padding-right: 8px;*/
  color: black;
}
.bpm span {
  font-size: 12px;
  font-weight: 600;
  font-family: 'proxima-nova';
  color: silver;
  padding-left: 4px;
}

.datePopover {
  margin-left: -6px;
}
.datePopoverWithSelection {
  margin-left: -60px;
}

.patientPopover {
  margin-left: -25px;
}

.analysisPopover {
  margin-left: 123px;
}
.analysisPopoverEkgReview {
  margin-left: 40px;
}
.analysisPopoverWithSelection {
  margin-left: 97px;
}
.analysisPopoverEkgReviewWithSelection {
  margin-left: 13px;
}
.interpretationCommentPopover {
  margin-left: -34px;
}

.analysisByPopover {
  margin-left: -25px;
}

.bpmPopover {
  margin-left: 59px;
}

.patientOption p {
  margin: 0;
}

.patientOption p:first-of-type {
  color: black;
}

.exportToEmail {
  min-width: 119px;
  justify-content: flex-start;
}

.bulkActionRemoveModal {
  width: 465px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
}

