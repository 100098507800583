@value dark-grey from '../../../../Assets/styles/variables/colors.css';

.markerContainer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.marker {
  fill: dark-grey;
  fill-opacity: .2;
}
.markerSvg {
  width: auto;
}
