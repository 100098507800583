@value chartFontSize 12px;
@value dark-grey from '../../../../Assets/styles/variables/colors.css';

.chartHeading {
  position: absolute;
 /*
  * this `top` should equal the amount of top padding
  * on the outer scrolling container
  */
  top: 8px;
  left: 0px;
  margin: 0;
  z-index: 10;
  background: rgba(255,255,255, .8);
  padding-left: 32px;
}
.yAxisTicks {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 64px;
  background-color: rgba(255, 255, 255, .8);
  z-index: 9;
}
.yTick text {
  fill: dark-grey;
  font-size: chartFontSize;
}
.yAxisTickGroup {
  position: relative;
}
.yAxisTick {
  color: #ccc;
}
.label {
  fill: dark-grey;
  font-size: chartFontSize;
}
.yAxisTicks {
  composes: yAxisTicks;
  user-select: none;
  pointer-events: none;
}
.defaultPrimaryAxis {
  composes: yAxisTicks;
}
.tabOpenPrimaryAxis {
  composes: yAxisTicks;
  top: 125px;
  /*[>add up the secodary chart + borders<]*/
}
.tabOpenSecondaryAxis {
  composes: yAxisTicks;
}
.defaultPrimaryHeading {
  composes: chartHeading;
}
.tabOpenPrimaryHeading {
  composes: chartHeading;
  top: 130px; /*was 195*/
}
.tabOpenSecondaryHeading {
  composes: chartHeading;
}
