@value popoverBorder, popoverShadow, silver, black, white, bg-white, light-grey, tableConfirmedColor from '../../../Assets/styles/variables/colors.css';

.weightTable {
  height: 100%;
  position: relative;
}

.datePopover {
  margin-left: -16px;
}
.datePopoverWithSelection {
  margin-left: -70px;
}

.sourcePopover {
  margin-left: 97px;
}
.sourcePopoverWithSelection {
  margin-left: 84px;
}

.categoryPopover {
  margin-left: 97px;
}
.categoryPopoverWithSelection {
  margin-left: 84px;
}

.bmiPopover {
  margin-left: -75px;
}

.weightPopover {
  margin-left: -135px;
}
