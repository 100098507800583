@value silver, black, light-teal from '../../Assets/styles/variables/colors.css';

.activePageTab,
.pageTab {
  margin: 0 16 0 16;
  outline: none;
  background-color: transparent;
  border: none;
  color: silver;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Work Sans';
  text-transform: capitalize;
  width: 160px;
  letter-spacing: 0.75px;
}
.pageTab:hover {
  color: black;
}

.pageTab:first-child {
  margin-left: 6px;
}

.activePageTab {
  color: #067f6f;
  position: relative;
  border-bottom: '4px solid #067F6F !important';
}
.activePageTab::after {
  position: absolute;
  bottom: -7px;
  left: 0;
  content: '';
  height: 3px;
  width: 100%;
  border-bottom: '4px solid #067F6F';
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  /* background-color: light-teal; */
}
