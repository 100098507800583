@value light-grey from '../../Assets/styles/variables/colors.css';

@value contentWidth from '../../Assets/styles/variables/global.css';

.pageTabs {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  /* margin: 0 auto 12px; */
  /* width: calc(contentWidth - (55px * 2)); */
  /* width: contentWidth; */
  /* border-bottom: 1px solid light-grey; */
}
